<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>更新日志</a-breadcrumb-item>
        </a-breadcrumb>

        <div class="main-box notification_box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-button @click="toback">返回</a-button>
                </div>
            </div>
            <div @click.stop class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta>
                            <a slot="title">{{item.upgrade_version}} <span>【{{item.upgrade_date}}】</span></a>
                            <div class="upgrade_list_description" slot="description" v-html="item.upgrade_content"></div>
                        </a-list-item-meta>
                    </a-list-item>
                </a-list>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'notification',
        data() {
            return {
                list: [],
                loading:false,
            }
        },
        mixins:[tableMixins],
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('oaUpgradeLogAction', this.searchParams)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            toback(){
                this.$router.go(-1)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .notification_box{
        width: 70%;
    }
</style>

<style lang="scss">
    .upgrade_list_description{
        margin-bottom: 0 !important;
    }
</style>